import React from 'react';
import { useField } from 'formik';
import { Input } from 'antd';
import { InputProps, TextAreaProps } from 'antd/lib/input';

import { StyledFormItem, StyledLabel } from './Form.styles';

type FieldProps = {
	name: string;
	label?: string;
	icon?: React.ReactNode;
	withLabel?: boolean;
	isTextArea?: boolean;
};

type Props = FieldProps & InputProps & TextAreaProps;

export const FormInput: React.FC<Props> = ({
	icon,
	label,
	name,
	withLabel,
	isTextArea,
	...rest
}) => {
	const [{ value, onChange }, { touched, error }] = useField(name);
	const errorMessage = touched && error;
	const status = errorMessage ? 'error' : '';
	const getValue = () => {
		if (typeof value === 'string') {
			return value;
		}
		if (typeof value === 'number') {
			return value;
		}
		return '';
	};
	return (
		<StyledFormItem validateStatus={status} help={errorMessage}>
			<>
				{withLabel && <StyledLabel>{label}</StyledLabel>}
				{isTextArea ? (
					<Input.TextArea
						name={name}
						placeholder={label}
						onChange={onChange}
						data-test-id={`form-item-${name}`}
						value={value}
						{...rest}
					/>
				) : (
					<Input
						prefix={icon && icon}
						placeholder={label}
						name={name}
						onChange={onChange}
						value={getValue()}
						data-test-id={`form-item-${name}`}
						{...rest}
					/>
				)}
			</>
		</StyledFormItem>
	);
};
