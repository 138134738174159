import styled from '@emotion/styled';
import { Form } from 'antd';

import { Colors } from '../../themeVariables';

export const LoginWrapper = styled.form`
	width: 500px;
	min-height: 330px;
	display: flex;
	flex-direction: column;
	background-color: ${Colors.WHITE};
	padding: 10px 40px;
	button {
		margin-bottom: 25px;
	}
`;

export const FormItemStyled = styled(Form.Item)`
	position: relative;
	margin-bottom: 35px;
`;

export const Logo = styled.img`
	width: 150px;
	margin: 0 auto;
`;

export const LanguageSwitchWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin: 15px;
	button {
		color: gray;
	}
`;
