import React from 'react';
import styled from '@emotion/styled';

import { Colors } from '../../themeVariables';
import { OrderStatus } from '../../__generated__/types';

type Props = { status: OrderStatus; children: string };
const borderSize = 1;

const buildBackgroundStyle = (styles: string, color: string) =>
	(styles += `background-color: ${color};`);
const buildBorderStyle = (styles: string, color: string) =>
	(styles += `border: ${borderSize}px solid ${color};`);

const StatusLabel = styled.div<Props>`
	align-items: center;
	border-radius: 5px;
	box-sizing: border-box;
	color: inherit;
	display: flex;
	font-size: 13px;
	font-weight: 700;
	height: 32px;
	justify-content: center;
	padding: 4px;
	text-align: center;
	${({ status }) => {
		let statusStyles = '';
		if (
			status === OrderStatus.ACCEPTED ||
			status === OrderStatus.REJECTED ||
			status === OrderStatus.QUOTE_REQUESTED ||
			status === OrderStatus.QUOTE_SENT
		) {
			statusStyles += `
			color: ${Colors.WHITE};
			`;
			statusStyles = buildBackgroundStyle(statusStyles, Colors[status]);
		} else {
			statusStyles = buildBorderStyle(statusStyles, Colors[status]);
		}
		return statusStyles;
	}}
`;

export const DisplayStatus: React.FC<Props> = (props) => {
	return (
		<StatusLabel data-test-id="status-label" {...props}>
			{props.children}
		</StatusLabel>
	);
};
