/* eslint-disable import/order */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { loadModules } from 'esri-loader';
import axios from 'axios';
import { FieldArray } from 'formik';
import { dummyMaterialLocation } from '../../NewOrder/NewOrder';
import { StyledLabel } from '../../FormItem/Form.styles';

import './Map.style.css';
import { Button, Radio } from 'antd';

export const LocationModal = (props) => {
	const { t } = useTranslation();
	const [value, setValue] = React.useState(1);
	const mapRef = useRef<HTMLDivElement>(null);
	const y_input = useRef(null);
	const x_input = useRef(null);
	const resolution = useRef(null);
	const price = useRef(null);
	const units = useRef(null);

	const calcPrice = () => {
		return axios
			.get(
				`https://quotes.priusintelli.com/area-price/431B57468284E/${
					value === 1 ? 'ac' : 'sm'
				}/${(units as any).current.value}/${(y_input as any).current.value}/${
					(x_input as any).current.value
				}/${(resolution as any).current.value}`,
			)
			.then((res) => {
				(price.current as any).value = res.data.price;
			})
			.catch((error) => {
				console.error(error);
			});
	};

	useEffect(() => {
		// lazy load the required ArcGIS API for JavaScript modules and CSS
		loadModules(
			[
				'esri/Map',
				'esri/views/MapView',
				'esri/Graphic',
				'esri/geometry/support/webMercatorUtils',
			],
			{ css: true },
		).then(([ArcGISMap, MapView, Graphic, webMercatorUtils]) => {
			const map = new ArcGISMap({
				basemap: 'topo-vector',
			});

			// load the map view at the ref's DOM node
			const view = new MapView({
				container: mapRef.current,
				map: map,
				center: [-118, 34],
				zoom: 4,
			});
			const point = {
				type: 'point', // autocasts as new Point()
				longitude: -103.763,
				latitude: 19.24,
			};

			const markerSymbol = {
				type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
				url:
					'https://daraobeirne.github.io/kisspng-drawing-pin-world-map-logo-push-vector-5ae029f6ddeaf4.198342921524640246909.png',
				width: '30px',
				height: '30px',
			};

			const graphic = new Graphic({
				geometry: webMercatorUtils.geographicToWebMercator(point),
				symbol: markerSymbol,
			});

			view.graphics.add(graphic);

			let isDragging = false;
			view.when().then(() =>
				view.on('drag', (e: any) => {
					// if you've started dragging on the graphic in question
					view.hitTest(e).then((res: any) => {
						// set isDragging to true at the start
						if (res.results[0].graphic === graphic && e.action === 'start')
							isDragging = true;
						// set isDragging to false at the end
						else if (e.action === 'end') isDragging = false;

						// SET VALUE RESULT
						(x_input.current as any).value = res.results[0].mapPoint.longitude.toFixed(
							4,
						);
						(y_input.current as any).value = res.results[0].mapPoint.latitude.toFixed(
							4,
						);

						console.log(res);
					});

					if (isDragging) {
						// prevent the map being panned mid-drag
						e.stopPropagation();
						// Update the graphic's geometry to the new drag pos
						graphic.geometry = view.toMap(e);
					}
				}),
			);
			return () => {
				if (view) {
					// destroy the map view
					view.container = null;
				}
			};
		});
	});

	const closeModalProp = () => {
		props.closeModalProp();
	};

	const setValuesLocation = (arrayHelpers) => {
		dummyMaterialLocation.uom = value === 1 ? 'ac' : 'sm';
		dummyMaterialLocation.units = (units as any).current.value;
		dummyMaterialLocation.longitude = (y_input as any).current.value;
		dummyMaterialLocation.latitude = (x_input as any).current.value;
		dummyMaterialLocation.resolution = (resolution as any).current.value;
		dummyMaterialLocation.totalPrice = (price.current as any).value;
		arrayHelpers.push(dummyMaterialLocation);
		closeModalProp();
	};

	const onChange = (e) => {
		console.log('radio checked', e.target.value);
		setValue(e.target.value);
	};

	return (
		<>
			<Radio.Group className="radioBox" onChange={onChange} value={value}>
				<Radio value={1}>Acres</Radio>
				<Radio value={2}>Miles</Radio>
			</Radio.Group>
			<React.Fragment>
				<div className="webmap" ref={mapRef} />;
				<FieldArray
					name="items"
					render={(arrayHelpers) => (
						<>
							<Button
								type="primary"
								className="btnSaveClose"
								style={{
									height: 42,
									marginBottom: 25,
									marginRight: 15,
								}}
								onClick={() => setValuesLocation(arrayHelpers)}
							>
								{t('Save and Close')}
							</Button>
						</>
					)}
				></FieldArray>
				<div id="data_map">
					<StyledLabel className="yCordLabel">{t('latitude')}</StyledLabel>
					<input
						type="number"
						ref={y_input}
						className="yCord"
						name="y"
						id="y_coordinate"
						placeholder="Move around the map"
					/>
					<StyledLabel className="xCordLabel">{t('longitude')}</StyledLabel>
					<input
						readOnly
						type="number"
						ref={x_input}
						className="xCord"
						name="x"
						id="x_coordinate"
						placeholder="Move around the map"
					/>
					<StyledLabel className="unitLabel">{t('units')}</StyledLabel>
					<input
						placeholder="Enter units"
						type="number"
						ref={units}
						className="unitInput"
						name="unit"
						id="unit"
					/>
					<StyledLabel className="resolutionLabel">{t('Resolution')}</StyledLabel>
					{value === 1 ? (
						<select
							name="resolution"
							className="resolutionInput"
							ref={resolution}
							id="resolutions"
						>
							<option value="5">5cm</option>
							<option value="10">10cm</option>
						</select>
					) : (
						<select
							name="resolution"
							className="resolutionInput"
							ref={resolution}
							id="resolutions"
						>
							<option value="7.5">7,5cm</option>
							<option value="12.5">12,5cm</option>
						</select>
					)}
					<StyledLabel className="priceLabel">{t('Price')}</StyledLabel>
					<input
						type="number"
						readOnly
						ref={price}
						className="priceInput"
						name="price"
						id="price"
					/>
				</div>
			</React.Fragment>
			<button className="calculateBtn" type="button" onClick={calcPrice}>
				Calculate
			</button>
		</>
	);
};
