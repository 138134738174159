import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikErrors, Formik } from 'formik';
import {
	EnvironmentOutlined,
	HomeOutlined,
	NumberOutlined,
	HddOutlined,
	UserOutlined,
	MailOutlined,
	PhoneOutlined,
	ContactsOutlined,
} from '@ant-design/icons';
import { Input, Row, Col, Checkbox } from 'antd';

import { StyledFormItem, StyledForm, StyledDivider } from '../FormItem/Form.styles';
import { FormInput } from '../FormItem/FormInput';

import { IdentificationNumberInput } from './IdentificationNumberInput';

export type UserFormValues = {
	name: string | null;
	identificationNumber: string | null;
	taxIdentificationNumber: string | null;
	personName: string | null;
	phone: string | null;
	email: string | null;
	note: string | null;
	allowedBankPayments: boolean;
	addresses: {
		isPrimary: boolean;
		street: string | null;
		city: string | null;
		postNumber: string | null;
		state?: string | null;
		address?: string | null;
		address2?: string | null;
	}[];
};

type Props = {
	initialValues: UserFormValues;
	onSubmit: (values: UserFormValues, resetForm: () => void) => Promise<void>;
	submitButton: React.ReactNode;
};

function onChange(e, values, setFieldValue) {
	if (e.target.checked === true) {
		values.addresses[1].street = values.addresses[0].street;
		setFieldValue(`addresses.${1}.street`, values.addresses[0].street);
		values.addresses[1].city = values.addresses[0].city;
		setFieldValue(`addresses.${1}.city`, values.addresses[0].city);
		values.addresses[1].postNumber = values.addresses[0].postNumber;
		setFieldValue(`addresses.${1}.postNumber`, values.addresses[0].postNumber);
		values.addresses[1].state = values.addresses[0].state;
		setFieldValue(`addresses.${1}.state`, values.addresses[0].state);
		values.addresses[1].address = values.addresses[0].address;
		setFieldValue(`addresses.${1}.address`, values.addresses[0].address);
		values.addresses[1].address2 = values.addresses[0].address2;
		setFieldValue(`addresses.${1}.address2`, values.addresses[0].address2);
	} else if (e.target.checked === false) {
		values.addresses[1].street = '';
		setFieldValue(`addresses.${1}.street`, '');
		values.addresses[1].city = '';
		setFieldValue(`addresses.${1}.city`, '');
		values.addresses[1].postNumber = '';
		setFieldValue(`addresses.${1}.postNumber`, '');
		values.addresses[1].state = '';
		setFieldValue(`addresses.${1}.state`, '');
		values.addresses[1].address = '';
		setFieldValue(`addresses.${1}.address`, '');
		values.addresses[1].address2 = '';
		setFieldValue(`addresses.${1}.address2`, '');
	}
	console.log(values.addresses);
}

export const CustomerForm: React.FC<Props> = (props) => {
	const { t } = useTranslation();

	return (
		<Formik<UserFormValues>
			initialValues={props.initialValues}
			onSubmit={async (values, { resetForm }) => {
				await props.onSubmit(values, resetForm);
			}}
			validate={(values) => {
				const errors: FormikErrors<UserFormValues> = {};
				if (!values.name) {
					errors.name = t('missing_company_name');
				}
				return errors;
			}}
		>
			{({ values, setFieldValue, handleChange, handleSubmit }) => (
				<StyledForm onSubmit={handleSubmit}>
					<Row gutter={32}>
						<Col xs={24} md={12}>
							<FormInput
								name="name"
								label={t('Company name')}
								icon={<ContactsOutlined />}
							/>
							<Row gutter={16}>
								<Col span={12}>
									<IdentificationNumberInput />
								</Col>
								<Col span={12}>
									<FormInput
										name="taxIdentificationNumber"
										label={t('Tax identification number')}
										icon={<HddOutlined />}
									/>
								</Col>
							</Row>
							<StyledDivider orientation="left">{t('Contact person')}</StyledDivider>
							<FormInput
								name="personName"
								label={t('Contact person name')}
								icon={<UserOutlined />}
							/>
							<Row gutter={16}>
								<Col span={12}>
									<FormInput
										name="email"
										label={t('Email')}
										icon={<MailOutlined />}
									/>
								</Col>
								<Col span={12}>
									<FormInput
										name="phone"
										label={t('Phone')}
										icon={<PhoneOutlined />}
									/>
								</Col>
							</Row>
						</Col>
						<Col xs={24} md={12}>
							<Checkbox
								name="allowedBankPayments"
								onClick={() =>
									setFieldValue(
										'allowedBankPayments',
										!values.allowedBankPayments,
									)
								}
								checked={values.allowedBankPayments}
							>
								{t('Allow bank payments')}
							</Checkbox>
							<StyledFormItem label={t('Note')}>
								<Input.TextArea
									rows={4}
									name="note"
									placeholder={t('customer_note_placeholder')}
									onChange={handleChange}
									value={values.note || ''}
								/>
							</StyledFormItem>
						</Col>
					</Row>
					<Row gutter={32}>
						{values.addresses
							.sort(({ isPrimary }) => (isPrimary ? -1 : 1))
							.map((_, i) => (
								<Col xs={24} md={12} key={i}>
									<StyledDivider orientation="left">
										{i === 0 ? t('Shipping address') : t('Billing address')}
									</StyledDivider>
									{i === 0 ? null : (
										<Checkbox
											style={{
												marginTop: 5,
												marginBottom: 20,
											}}
											onChange={() => onChange(event, values, setFieldValue)}
										>
											Same as shipping address
										</Checkbox>
									)}
									<Row gutter={12}>
										<Col span={8}>
											<StyledFormItem>
												<Input
													name={`addresses.${i}.street`}
													prefix={<EnvironmentOutlined />}
													placeholder={t('Street')}
													onChange={handleChange}
													value={values.addresses[i].street || ''}
												/>
											</StyledFormItem>
										</Col>
										<Col span={8}>
											<StyledFormItem>
												<Input
													name={`addresses.${i}.address`}
													prefix={<EnvironmentOutlined />}
													placeholder={t('Address')}
													onChange={handleChange}
													value={values.addresses[i].address || ''}
												/>
											</StyledFormItem>
										</Col>
										<Col span={8}>
											<StyledFormItem>
												<Input
													name={`addresses.${i}.address2`}
													prefix={<EnvironmentOutlined />}
													placeholder={t('Address2')}
													onChange={handleChange}
													value={values.addresses[i].address2 || ''}
												/>
											</StyledFormItem>
										</Col>
									</Row>
									<Row gutter={12}>
										<Col span={8}>
											<StyledFormItem>
												<Input
													name={`addresses.${i}.city`}
													prefix={<HomeOutlined />}
													placeholder={t('City')}
													onChange={handleChange}
													value={values.addresses[i].city || ''}
												/>
											</StyledFormItem>
										</Col>
										<Col span={8}>
											<StyledFormItem>
												<Input
													name={`addresses.${i}.state`}
													prefix={<HomeOutlined />}
													placeholder={t('State')}
													onChange={handleChange}
													value={values.addresses[i].state || ''}
												/>
											</StyledFormItem>
										</Col>
										<Col span={8}>
											<StyledFormItem>
												<Input
													name={`addresses.${i}.postNumber`}
													prefix={<NumberOutlined />}
													placeholder={t('Post Number')}
													onChange={handleChange}
													value={values.addresses[i].postNumber || ''}
												/>
											</StyledFormItem>
										</Col>
									</Row>
								</Col>
							))}
					</Row>
					{props.submitButton}
				</StyledForm>
			)}
		</Formik>
	);
};
