import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik, FieldArray } from 'formik';
import { Row, Col, Button, Input } from 'antd';
import 'antd/es/date-picker/style/index';
import { NumberOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { StyledForm, StyledLabel, StyledDivider, StyledFormItem } from '../FormItem/Form.styles';
import { FormInput } from '../FormItem/FormInput';
import { dummyMaterialItem } from '../NewOrder/NewOrder';
import { CURRENCY_SUFFIX } from '../../config';
import { useCurrencyFormatter } from '../../locales/useCurrencyFormatter';
import { OrderStatus } from '../../__generated__/types';

import { CustomerPicker, CustomerOption } from './CustomerPicker';
import { OrderItemField } from './OrderItemField/OrderItemField';
import { getOrderValidationSchema } from './validateOrder';
import { StyledOrderNumberWrapper, OrderSummaryWrapper } from './OrderForm.styles';
import { getTotalPriceIncludingTax } from './calculateSummary';
import { OrderStatusSelect } from './OrderStatusSelect';
import { UrgentSlider } from './UrgentSlider';
import { DatePickerInput } from './OrderItemField/DatePicker';
import { Modal } from './Location/Modal';

export type OrderFormItem = {
	id?: string;
	name?: string;
	materialId?: string;
	width?: number;
	height?: number;
	pieces?: number;
	totalPrice?: number;
	totalTax?: number;
	filePath?: string;
	units?: number;
	uom?: string;
	resolution?: number;
	latitude?: number;
	longitude?: number;
};

export type OrderFormValues = {
	number: number | null;
	urgency: number;
	status?: OrderStatus;
	customerId?: string;
	totalPrice?: number;
	totalTax?: number;
	note?: string;
	items: OrderFormItem[];
	dueDate: string;
	projectName?: string;
	region?: string;
};

type Props = {
	initialValues: OrderFormValues;
	onSubmit: (values: OrderFormValues, resetForm: () => void) => Promise<void>;
	submitButton: React.ReactNode;
	isNumberEditable: boolean;
	extraCustomer: CustomerOption | null;
};

export const OrderForm: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { currencyFormatter } = useCurrencyFormatter();
	const [showModal, setShowModal] = useState(false);
	const openModal = () => {
		setShowModal((prev) => !prev);
	};

	return (
		<Formik<OrderFormValues>
			initialValues={props.initialValues}
			onSubmit={async (values, { resetForm }) => {
				await props.onSubmit(values, resetForm);
			}}
			validationSchema={getOrderValidationSchema(t)}
			enableReinitialize
		>
			{({ handleSubmit, values, handleChange }) => (
				<StyledForm onSubmit={handleSubmit}>
					<Row gutter={8}>
						<Col lg={4}>
							<span className="sub-title">{t('Order details')}</span>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col sm={5}>
							<FormInput
								name="projectName"
								withLabel={true}
								label={t('Project name')}
								isTextArea={false}
								rows={4}
								value={values.projectName || ''}
							></FormInput>
						</Col>
						<Col lg={4}>
							<StyledOrderNumberWrapper>
								<FormInput
									name="number"
									label={t('Order number')}
									icon={<NumberOutlined />}
									withLabel
									type="number"
									disabled={!props.isNumberEditable}
								/>
							</StyledOrderNumberWrapper>
						</Col>
						<Col lg={6}>
							<OrderStatusSelect />
						</Col>
						<Col sm={6}>
							<Row>
								<Col sm={18} offset={3}>
									<UrgentSlider />
								</Col>
							</Row>
						</Col>
					</Row>
					<Row gutter={8}>
						<Col lg={5}>
							<CustomerPicker extraCustomer={props.extraCustomer} />
						</Col>
						<Col sm={4}>
							<DatePickerInput
								name="dueDate"
								withLabel={true}
								label={t('Due date')}
							></DatePickerInput>
						</Col>
						<Col sm={6}>
							<FormInput
								name="region"
								withLabel={true}
								label={t('Region')}
								isTextArea={false}
								rows={4}
								value={values.region || ''}
							></FormInput>
						</Col>
					</Row>
					<StyledDivider />
					<Row gutter={8}>
						<Col lg={4}>
							<span className="sub-title">Product Details</span>
						</Col>
					</Row>
					<FieldArray
						name="items"
						render={(arrayHelpers) => (
							<>
								<Row>
									<Col>
										<Button
											type="primary"
											style={{
												height: 42,
												marginBottom: 25,
												marginRight: 15,
											}}
											icon={<PlusCircleOutlined />}
											onClick={() => arrayHelpers.push(dummyMaterialItem)}
										>
											{t('Add item')}
										</Button>
										<Button
											type="primary"
											style={{ height: 42, marginBottom: 25 }}
											icon={<PlusCircleOutlined />}
											onClick={openModal}
										>
											{t('Add Location')}
										</Button>
									</Col>
								</Row>
								{values.items.length > 0 &&
									values.items.map((item, index) =>
										item.name?.includes('Location') ? (
											<div>
												<Row gutter={6}>
													{item.uom === 'ac' ? (
														<Col sm={3}>
															<StyledLabel>
																{t('units') + ': ' + t('acres')}
															</StyledLabel>
														</Col>
													) : (
														<Col sm={3}>
															<StyledLabel>
																{t('units') + ': ' + t('miles')}
															</StyledLabel>
														</Col>
													)}
													<Col sm={3}>
														<StyledLabel>{t('Resolution')}</StyledLabel>
													</Col>
													<Col sm={2}>
														<StyledLabel>{t('latitude')}</StyledLabel>
													</Col>
													<Col sm={2}>
														<StyledLabel>{t('longitude')}</StyledLabel>
													</Col>
													<Col sm={3}>
														<StyledLabel>
															{t('Attachments')}
														</StyledLabel>
													</Col>
													<Col sm={3}>
														<StyledLabel>{t('Price')}</StyledLabel>
													</Col>
												</Row>
												<OrderItemField
													key={item.id || index}
													index={index}
													arrayHelpers={arrayHelpers}
												/>
											</div>
										) : (
											<div>
												<Row gutter={6}>
													<Col sm={4}>
														<StyledLabel>{t('Product')}</StyledLabel>
													</Col>
													<Col sm={5}>
														<StyledLabel>{t('Name')}</StyledLabel>
													</Col>
													<Col sm={4}>
														<StyledLabel>
															{t('Attachments')}
														</StyledLabel>
													</Col>
													<Col sm={3}>
														<StyledLabel>{t('Price')}</StyledLabel>
													</Col>
												</Row>
												<OrderItemField
													key={item.id || index}
													index={index}
													arrayHelpers={arrayHelpers}
												/>
											</div>
										),
									)}
							</>
						)}
					/>
					<StyledDivider />
					<Row gutter={8} style={{ marginTop: 15 }}>
						<Col sm={5}>
							<StyledFormItem>
								<StyledLabel>{t('Note')}</StyledLabel>
								<Input.TextArea
									rows={4}
									name="note"
									placeholder={t('note_placeholder')}
									onChange={handleChange}
									data-test-id="order-form-note"
									value={values.note || ''}
								/>
							</StyledFormItem>
						</Col>
					</Row>
					<StyledDivider />

					<Row>
						<Col sm={8} style={{ marginBottom: 20 }}>
							<OrderSummaryWrapper>
								<FormInput
									name="totalPrice"
									label={t('Total price')}
									type="number"
									suffix={CURRENCY_SUFFIX}
									withLabel
								/>
								{!!getTotalPriceIncludingTax(values) && (
									<div>
										<StyledLabel>{t('Total price including tax')}</StyledLabel>
										<span>
											{currencyFormatter(
												getTotalPriceIncludingTax(values) || 0,
											)}
										</span>
									</div>
								)}
							</OrderSummaryWrapper>
						</Col>
					</Row>
					{props.submitButton}
					<Modal showModal={showModal} setShowModal={setShowModal} />
				</StyledForm>
			)}
		</Formik>
	);
};
