import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useFormik, FormikErrors } from 'formik';
import { ApolloError } from 'apollo-client';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { CaretRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import LogoImage from '../../images/priusintelli-logo.jpg';
import {
	LoginMutation,
	LoginMutationVariables,
	UserRole,
	CreateUser,
	CreateUserVariables,
	GetAllUsers,
} from '../../__generated__/types';
import { CenteredWrapper } from '../CenteredWrapper/CenteredWrapper';
import { useAppDispatch } from '../../appContext/context';
import Auth from '../../Auth';
import { CREATE_USER, GET_ALL_USERS } from '../Users/queries';

import { LOGIN_MUTATION } from './queries';
import './LoginForm.css';
import * as S from './LoginForm.styles';

type FormValues = {
	username: string;
	password: string;
};

const Background = styled.div`
	align-items: center;
	background-color: rgba(37, 62, 93, 0.6);
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;
`;

export const LoginForm: React.FC = () => {
	const auth = new Auth();
	const token = window.location.hash.substr(10);

	const [email] = useState(token ? auth.currentUser(token).emails.toString() : '');
	const [name] = useState(
		token ? `${auth.currentUser(token).firstName} ${auth.currentUser(token).lastName}` : '',
	);
	const [password] = useState(token ? auth.currentUser(token).oid : '');

	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const [login, { loading }] = useMutation<LoginMutation, LoginMutationVariables>(LOGIN_MUTATION);
	const [createUser] = useMutation<CreateUser, CreateUserVariables>(CREATE_USER, {
		onCompleted: () => {
			console.log('User created success');
		},
		update: (cache, { data }) => {
			const cached = cache.readQuery<GetAllUsers>({ query: GET_ALL_USERS });
			if (cached === null || !data) {
				return;
			}
			const { getAllUsers } = cached;
			cache.writeQuery<GetAllUsers>({
				query: GET_ALL_USERS,
				data: {
					getAllUsers: [...getAllUsers, data.addUser],
				},
			});
		},
	});
	const { errors, handleSubmit, values, handleChange, isValid, setErrors, touched } = useFormik<
		FormValues
	>({
		initialValues: {
			username: email,
			password: password,
		},
		validate: (values) => {
			const errors: FormikErrors<FormValues> = {};
			if (!values.password) {
				errors.password = t('password_required');
			}
			if (!values.username) {
				errors.username = t('username_required');
			}
			return errors;
		},
		onSubmit: async ({ username, password }) => {
			try {
				const result = await login({ variables: { email: username, password: password } });
				if (result.data?.login) {
					dispatch({
						type: 'SET_CURRENT_USER',
						user: result.data.login.user,
					});
					localStorage.setItem('auth-token', result.data.login.token);
				}
			} catch (err) {
				if (err instanceof ApolloError) {
					if (err.graphQLErrors[0].extensions?.code === 'USER_NOT_FOUND') {
						setErrors({
							username: t('user_not_found'),
						});
					}
					if (err.graphQLErrors[0].extensions?.code === 'USER_NOT_FOUND' && token) {
						await createUser({
							variables: {
								input: {
									email: email,
									name: name,
									password: password,
									role: UserRole.FACTORY,
								},
							},
						});
						handleSubmit();
					}
					if (err.graphQLErrors[0].extensions?.code === 'INVALID_PASSWORD') {
						setErrors({
							password: t('invalid_password'),
						});
					}
				}
			}
		},
	});

	useEffect(() => {
		try {
			if (token) {
				handleSubmit();
			}
		} catch (err) {
			console.log(err);
		}
	}, []);

	return (
		<CenteredWrapper hasBackgroundImage>
			<Background>
				<S.LoginWrapper onSubmit={handleSubmit}>
					<S.Logo src={LogoImage} />
					<S.FormItemStyled
						validateStatus={touched.username && errors.username ? 'error' : ''}
						help={touched.username && errors.username}
					>
						<input
							className="input-form"
							name="username"
							onChange={handleChange}
							value={values.username}
							data-test-id="login-username"
							required
						/>
						<span className="highlight"></span>
						<span className="bar"></span>
						<label className="label-form">{t('Username')}</label>
					</S.FormItemStyled>
					<S.FormItemStyled
						validateStatus={touched.password && errors.password ? 'error' : ''}
						help={touched.password && errors.password}
					>
						<input
							className="input-form"
							name="password"
							onChange={handleChange}
							value={values.password}
							type="password"
							data-test-id="login-password"
							required
						/>
						<span className="highlight"></span>
						<span className="bar"></span>
						<label className="label-form">{t('Password')}</label>
					</S.FormItemStyled>
					<Button
						loading={loading}
						disabled={!isValid}
						htmlType="submit"
						type="primary"
						data-test-id="login-submit-button"
					>
						{t('Log In')}
						<CaretRightOutlined />
					</Button>
					<Button
						loading={loading}
						disabled={!isValid}
						type="ghost"
						href="https://aptusb2c.b2clogin.com/aptusb2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_PI_firstlogin_susi&client_id=2cc0231d-77ae-4f7b-8339-196d6d619b87&nonce=defaultNonce&redirect_uri=https%3A%2F%2Forders.priusintelli.com%2F&scope=openid&response_type=id_token&prompt=login"
					>
						{t('Sign in OpenID')}
					</Button>
				</S.LoginWrapper>
			</Background>
		</CenteredWrapper>
	);
};
