/* eslint-disable  @typescript-eslint/camelcase */
import React, { useState } from 'react';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { useField } from 'formik';

import { StyledFormItem, StyledFormFile } from '../../FormItem/Form.styles';
import { uploadFile, uploadFileVariables } from '../../../__generated__/types';
import { UPLOAD_FILE } from '../../NewOrder/queries';

export const FileUploader: React.FC<{ name: string }> = ({ name }) => {
	const { t } = useTranslation();
	const [{ value }, { touched, error }, { setValue }] = useField(name);
	const [fileName, setFileName] = useState('');
	const errorMessage = touched && error;
	const status = errorMessage ? 'error' : '';

	const [uploadFileMutation, { loading }] = useMutation<uploadFile, uploadFileVariables>(
		UPLOAD_FILE,
		{
			onError: (err) => {
				message.error(t(`Something failed while uploading the file. ${err}`));
			},
			onCompleted: (data) => {
				message.success(<>{`File uploaded to ${data.singleUpload.url}`}</>);
				setValue(data.singleUpload.url);
			},
		},
	);

	const uploadFileHandler = async (file: File) => {
		setFileName(file.name);
		await uploadFileMutation({
			variables: {
				file,
			},
		});
	};

	return (
		<StyledFormItem validateStatus={status} help={errorMessage}>
			<>
				<StyledFormFile
					type="file"
					onChange={({
						target: {
							validity,
							files: [file],
						},
					}) => validity.valid && uploadFileHandler(file)}
				/>
				{loading ? (
					<span>Uploading!</span>
				) : value ? (
					<a href={value} target="_blank" rel="noopener noreferrer">
						{fileName}
					</a>
				) : null}
			</>
		</StyledFormItem>
	);
};
