import styled from '@emotion/styled';
import { Divider, Form } from 'antd';

import { Colors } from '../../themeVariables';

export const StyledForm = styled.form`
	padding: 15px 35px;
	.sub-title {
		display: inline-block;
		font-size: 18px;
		font-weight: 600;
		padding: 24px 0;
	}
`;

export const StyledDivider = styled(Divider)`
	&.ant-divider-with-text-left {
		font-size: 14px;
		margin: 8px 0;
	}
`;

export const StyledFormItem = styled(Form.Item)`
	margin-bottom: 5px;
	line-height: normal;
	display: flex;
	flex-direction: column;
	.ant-form-item-label {
		text-align: left;
	}
	.ant-form-item-control {
		line-height: normal;
		margin-bottom: 8px;
	}
`;

export const StyledLabel = styled.label`
	display: block;
	margin-bottom: 5px;
	font-weight: 700;
`;

export const StyledFormLabel = styled.label`
	font-weight: bold;
	text-transform: uppercase;
	color: ${Colors.GRAY4};
	margin-bottom: 4px;
	display: inline-block;
	z-index: -1;
`;

export const StyledFormFile = styled.input`
	color: transparent;
	max-width: 110px;
	::-webkit-file-upload-button {
		visibility: hidden;
	}
	::before {
		background-color: ${Colors.WHITE};
		border-radius: 2px;
		border: 1px solid ${Colors.BLUE5};
		box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
		color: ${Colors.BLUE5};
		content: 'Select a file';
		cursor: pointer;
		display: inline-block;
		font-size: 14px;
		height: 32px;
		line-height: 1.5715;
		padding: 4px 15px;
		touch-action: manipulation;
		transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
		user-select: none;
	}
	:hover::before {
		color: #fff;
		background-color: #52b0e3;
		border-color: #52b0e3;
	}
`;
