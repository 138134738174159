import React, { useEffect } from 'react';
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import LogoImage from '../../images/priusintelli-logo-without-background.png';
import { Logout } from '../Logout/Logout';
import { UserOverlay } from '../UserOverlay/UserOverlay';
import { MainMenu } from '../MainMenu/MainMenu';
import { LanguageSwitch } from '../LanguageSwitch/LanguageSwitch';
import { NewCustomer } from '../NewCustomer/NewCustomer';
import { ListCustomers } from '../ListCustomers/ListCustomers';
import { DetailCustomer } from '../DetailCustomer/DetailCustomer';
import { MaterialEdit } from '../Material/MaterialEdit';
import { NewOrder } from '../NewOrder/NewOrder';
import { ListOrders } from '../ListOrders/ListOrders';
import { DetailOrder } from '../DetailOrder/DetailOrder';
import { UserEdit } from '../Users/UserEdit';
import { QuoteRequested } from '../QuoteRequested/QuoteRequested';

import * as S from './MainWrapper.styles';

type RouteConfig = {
	path: string;
	title: string;
	Component?: React.ReactElement;
};

export const MainWrapper: React.FC = () => {
	const { t, i18n } = useTranslation();
	const location = useLocation();

	const menu = (
		<Menu>
			<Menu.Item>
				<Logout />
			</Menu.Item>
		</Menu>
	);

	const routes: RouteConfig[] = [
		{ path: '/orders/inprogress', title: t('In Progress') },
		{ path: '/orders/new', title: t('Add order'), Component: <NewOrder /> },
		{ path: '/orders/list', title: t('List orders'), Component: <ListOrders /> },
		{ path: '/orders/quote', title: t('Quote Requested'), Component: <QuoteRequested /> },
		{
			path: '/orders/:id',
			title: t('Order detail'),
			Component: <DetailOrder />,
		},
		{ path: '/customers/new', title: t('Add customer'), Component: <NewCustomer /> },
		{ path: '/customers/list', title: t('Customers'), Component: <ListCustomers /> },
		{
			path: '/customers/:id',
			title: t('Customer detail'),
			Component: <DetailCustomer />,
		},
		{ path: '/materials', title: t('Material'), Component: <MaterialEdit /> },
		{ path: '/users', title: t('Users administration'), Component: <UserEdit /> },
	];

	useEffect(() => {
		const currentRoute = routes.find((route) => route.path === location.pathname);

		if (currentRoute) {
			document.title = `${currentRoute.title} | Prius OMS`;
		}
	}, [location, i18n.language]);

	return (
		<S.BodyWrapper data-test-id="main-body-wrapper">
			<S.Aside>
				<S.LogoWrapper>
					<S.Logo src={LogoImage} />
				</S.LogoWrapper>
				<MainMenu />
			</S.Aside>
			<S.Main>
				<S.StyledNavbar>
					<LanguageSwitch />
					<Dropdown overlay={menu}>
						<a onClick={(e) => e.preventDefault()}>
							<UserOverlay />
							<S.btn>
								<DownOutlined />
							</S.btn>
						</a>
					</Dropdown>
				</S.StyledNavbar>
				<S.Content>
					<Switch>
						{routes.map(({ path, title, Component }) => (
							<Route path={path} key={path}>
								{Component ? Component : <S.PageTitle>{title}</S.PageTitle>}
							</Route>
						))}
						<Redirect from="/" to="/orders/new" />
					</Switch>
				</S.Content>
			</S.Main>
		</S.BodyWrapper>
	);
};
