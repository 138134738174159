import styled from '@emotion/styled';
import { Input } from 'antd';

import { Colors } from '../../themeVariables';

export const StyledTableWrapper = styled.div`
	padding: 0 25px;
	.ant-table-thead > tr > th {
		color: ${Colors.DARK_GRAY2};
		background-color: transparent;
		font-weight: 700;
	}
`;

export const StyledSearch = styled(Input.Search)`
	margin-bottom: 15px;
`;

export const BreadCrumbs = styled.span`
	color: #767676;
`;
