import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

export const useDateFormatter = () => {
	const { i18n } = useTranslation();

	const dateFormatter = (
		date: string,
		style: 'datetime' | 'time' | 'date' | 'plainshort' | 'iso' | 'plainshortusa',
	): string => {
		if (style === 'datetime') {
			return new Date(date).toLocaleDateString(i18n.language, {
				year: 'numeric',
				month: 'long',
				day: 'numeric',
				hour: 'numeric',
				minute: 'numeric',
			});
		}
		if (style === 'time') {
			return new Date(date).toLocaleDateString(i18n.language, {
				hour: 'numeric',
				minute: 'numeric',
			});
		}
		if (style === 'plainshort') {
			const [, plainDateString] = date.match(/(\d{4}-\d\d-\d\d)T\d\d:\d\d:\d\d.\d*Z/);
			return plainDateString;
		}
		if (style === 'plainshortusa') {
			const [, plainDateString] = date.match(/(\d{4}-\d\d-\d\d)T\d\d:\d\d:\d\d.\d*Z/);
			return plainDateString.replace('-', '/');
		}
		if (style === 'iso') {
			const ISOFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
			return format(new Date(date), ISOFormat);
		}
		return new Date(date).toLocaleDateString(i18n.language, {
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		});
	};

	return { f: dateFormatter };
};
