import styled from '@emotion/styled';

import BackgroundImage from '../../images/auth-background.jpg';

type Props = { hasBackgroundImage?: boolean };

export const CenteredWrapper = styled.div<Props>`
	padding: 30px 25px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f7f7f7;
	${({ hasBackgroundImage }) =>
		hasBackgroundImage &&
		`
	background: url(${BackgroundImage}) no-repeat center center;
	padding: 0;
	  `}
	background-size: cover;
`;
