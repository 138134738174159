import { TAX } from '../../config';

export type OrderItemCalculateParams = {
	pieces?: number;
	unitPrice?: number;
};

export const calculateRow = ({ pieces, unitPrice }: OrderItemCalculateParams) => {
	if (pieces === undefined || unitPrice === undefined) {
		throw new Error('All parameters need to be defined');
	}

	const price = Math.ceil(unitPrice);
	const tax = Math.ceil(price * TAX);

	return { price, tax };
};
