/* eslint-disable @typescript-eslint/no-explicit-any */
import decodeJWT from 'jwt-decode';

class Auth {
	isLoggedIn(token) {
		if (token) {
			return true;
		}

		return false;
	}

	// logout() {
	// 	b2cauth.signOut();
	// }

	getToken(token) {
		return token;
	}

	currentUser(token) {
		const decoded = decodeJWT(token);
		return {
			firstName: (decoded as any).given_name,
			lastName: (decoded as any).family_name,
			emails: (decoded as any).emails,
			oid: (decoded as any).oid,
		};
	}
}

export default Auth;
