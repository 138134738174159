import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import { useQuery, useMutation } from 'react-apollo';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import { useDateFormatter } from '../../locales/useDateFormatter';
import { PageTitle } from '../MainWrapper/MainWrapper.styles';
import { OrderForm, OrderFormValues, OrderFormItem } from '../OrderForm/OrderForm';
import {
	GetHighestOrderNumber,
	CreateOrder,
	CreateOrderVariables,
	OrderStatus,
} from '../../__generated__/types';
import { ValidatedOrder } from '../OrderForm/validateOrder';

import { GET_HIGHEST_ORDER_NUMBER, CREATE_ORDER } from './queries';

export const dummyMaterialItem: OrderFormItem = {
	materialId: '',
	name: '',
	pieces: 1,
	width: 2,
	height: 1,
	totalPrice: undefined,
	totalTax: undefined,
	filePath: '',
};

export const dummyMaterialLocation: OrderFormItem = {
	name: 'Location',
	width: 2,
	height: 1,
	pieces: 1,
	totalPrice: undefined,
	totalTax: undefined,
	filePath: '',
	units: undefined,
	uom: '',
	resolution: undefined,
	latitude: undefined,
	longitude: undefined,
};

const getInitialValues = (orderNumber: number | null): OrderFormValues => ({
	number: orderNumber,
	urgency: 1,
	status: OrderStatus.QUOTE_REQUESTED,
	customerId: undefined,
	totalPrice: undefined,
	totalTax: undefined,
	note: '',
	items: [dummyMaterialItem],
	dueDate: '',
	projectName: '',
	region: '',
});

export const NewOrder: React.FC = () => {
	const { t } = useTranslation();
	const { f } = useDateFormatter();
	const history = useHistory();

	const highestOrderNumberQuery = useQuery<GetHighestOrderNumber>(GET_HIGHEST_ORDER_NUMBER);
	const [createOrder, { loading }] = useMutation<CreateOrder, CreateOrderVariables>(
		CREATE_ORDER,
		{
			onError: (err) => {
				if (err.graphQLErrors[0].extensions?.code === 'ORDER_NUMBER_EXISTS') {
					message.error(t('order_number_exists'));
				}
			},
			onCompleted: (data) => {
				console.log(data);
				message.success(
					<>
						{t('Order number {{number}} created', {
							number: data.createOrder.number,
						})}{' '}
						<Button
							type="link"
							icon={<ArrowRightOutlined />}
							onClick={() => history.push(`/orders/${data.createOrder.number}`)}
						></Button>
					</>,
				);
			},
		},
	);

	const submitHandler = async (rawValues: OrderFormValues) => {
		const { number, dueDate, ...rest } = rawValues as ValidatedOrder; // gets triggered only when form is valid
		const dueDateISO = f(dueDate, 'iso');
		await createOrder({
			variables: {
				number,
				input: {
					...rest,
					dueDate: dueDateISO,
					items: rawValues.items.map((item) => ({
						...item,
						materialId: item.materialId || '',
						totalTax: parseFloat((item.totalTax as unknown) as string),
						totalPrice: parseFloat((item.totalPrice as unknown) as string),
						name: item.name || 'Location',
						units: parseFloat((item.units as unknown) as string),
						uom: item.uom || '',
						resolution: parseFloat((item.resolution as unknown) as string),
						latitude: parseFloat((item.latitude as unknown) as string),
						longitude: parseFloat((item.longitude as unknown) as string),
					})),
				},
			},
		});
		highestOrderNumberQuery.refetch();
	};

	const newOrderNumber = highestOrderNumberQuery.data
		? (highestOrderNumberQuery.data.getHighestOrderNumber || 0) + 1
		: null;

	return (
		<>
			<PageTitle>{t('Add order')}</PageTitle>
			<OrderForm
				initialValues={getInitialValues(newOrderNumber)}
				onSubmit={submitHandler}
				extraCustomer={null}
				isNumberEditable={true}
				submitButton={
					<Button
						type="primary"
						htmlType="submit"
						style={{ marginTop: 10 }}
						loading={loading}
						data-test-id="add-order-submit-button"
					>
						{t('Add order')}
					</Button>
				}
			/>
		</>
	);
};
