import React from 'react';
import { useField } from 'formik';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/es/date-picker/generatePicker';
import locale from 'antd/es/date-picker/locale/en_US';
import { parse } from 'date-fns';

import { StyledFormItem, StyledLabel } from '../../FormItem/Form.styles';

type FieldProps = {
	name: string;
	label?: string;
	withLabel?: boolean;
};

type Props = FieldProps;

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export const DatePickerInput: React.FC<Props> = ({ label, name, withLabel }) => {
	const [{ value }, { touched, error }, { setValue }] = useField(name);
	const errorMessage = touched && error;
	const status = errorMessage ? 'error' : '';

	return (
		<StyledFormItem validateStatus={status} help={errorMessage}>
			<>
				{withLabel && <StyledLabel>{label}</StyledLabel>}
				<DatePicker
					locale={locale}
					name="dueDate"
					onChange={(_, dateString) => {
						setValue(dateString);
					}}
					data-test-id={`form-date-${name}`}
					value={value ? parse(value, 'yyyy-MM-dd', new Date()) : null}
					style={{ width: '16rem' }}
				/>
			</>
		</StyledFormItem>
	);
};
