import React, { useEffect } from 'react';
import { useField, FieldArrayRenderProps, useFormikContext } from 'formik';
import { Col, Button, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';

import { OrderFormItem, OrderFormValues } from '../OrderForm';
import { FormInput } from '../../FormItem/FormInput';
import { CURRENCY_SUFFIX } from '../../../config';
import { calculateSummary } from '../calculateSummary';
import { client } from '../../../ApolloClient';
import { GET_ALL_MATERIALS } from '../../Material/queries';
import { GetAllMaterials } from '../../../__generated__/types';
import { calculateRow } from '../calculateRow';

import { MaterialPicker } from './MaterialPicker';
import { FileUploader } from './FileUploader';
import {
	StyledItemNumber,
	MaterialColumn,
	StyledOrderRow,
	HiddenDeleteButton,
} from './OrderItemField.styles';

type FieldProps = {
	index: number;
	arrayHelpers: FieldArrayRenderProps;
};

export const OrderItemField: React.FC<FieldProps> = ({ index, arrayHelpers }) => {
	const { t } = useTranslation();
	const itemName = `items.${index}`;
	const [{ value }, , { setValue }] = useField<OrderFormItem>(itemName);
	const { setFieldValue, values } = useFormikContext<OrderFormValues>();

	useEffect(() => {
		const { totalPrice, totalTax } = calculateSummary(values);
		setFieldValue('totalPrice', totalPrice);
		setFieldValue('totalTax', totalTax);
	}, [values.items]);

	useEffect(() => {
		if (value.materialId) {
			try {
				const allMaterials = client.readQuery<GetAllMaterials>({
					query: GET_ALL_MATERIALS,
				});
				const material = allMaterials?.getAllMaterials.find(
					({ id }) => id === value.materialId,
				);
				const { pieces = 1 } = value;
				const { price, tax } = calculateRow({ pieces, unitPrice: material?.price });
				setValue({ ...value, totalPrice: price, totalTax: tax });
			} catch (error) {
				setValue({ ...value });
			}
		} else {
			console.log('Entra al else');
			try {
				const { pieces = 1 } = value;
				console.log(value);
				const { price, tax } = calculateRow({
					pieces,
					unitPrice: value.totalPrice,
				});
				setValue({ ...value, totalPrice: price, totalTax: tax });
			} catch (error) {
				setValue({ ...value });
			}

			// setValue({
			// 	...value,
			// 	totalPrice: value.totalPrice,
			// 	totalTax: value.totalTax,
			// 	latitude: value.longitude,
			// 	longitude: value.longitude,
			// });
		}
	}, [value.materialId]);
	if (value.name?.includes('Location') && value.totalPrice != undefined) {
		return (
			<StyledOrderRow key={value.id || index} gutter={6}>
				<Col sm={3}>
					<MaterialColumn>
						<StyledItemNumber>{index + 1}.</StyledItemNumber>
						<FormInput readOnly name={`${itemName}.units`} label={t('UOM')} />
					</MaterialColumn>
				</Col>
				<Col sm={3}>
					<FormInput
						readOnly
						name={`${itemName}.resolution`}
						label={t('Resolution')}
						type="number"
					/>
				</Col>
				<Col sm={2}>
					<FormInput
						readOnly
						name={`${itemName}.latitude`}
						label={t('longitude')}
						type="number"
					/>
				</Col>
				<Col sm={2}>
					<FormInput
						readOnly
						name={`${itemName}.longitude`}
						label={t('latitude')}
						type="number"
					/>
				</Col>
				<Col sm={3}>
					<FileUploader name={`${itemName}.filePath`} />
				</Col>
				<Col sm={3}>
					<FormInput
						name={`${itemName}.totalPrice`}
						label={t('Price')}
						readOnly
						suffix={CURRENCY_SUFFIX}
						type="number"
					/>
					<HiddenDeleteButton>
						<Popconfirm
							title={t('Are you sure want to remove this item?')}
							onConfirm={() => arrayHelpers.remove(index)}
							placement="topRight"
							okText={t('Remove')}
							okType="danger"
						>
							<Button
								icon={<DeleteOutlined />}
								shape="circle-outline"
								type="link"
							></Button>
						</Popconfirm>
					</HiddenDeleteButton>
				</Col>
			</StyledOrderRow>
		);
	} else {
		return (
			<StyledOrderRow key={value.id || index} gutter={6}>
				<Col sm={4}>
					<MaterialColumn>
						<StyledItemNumber>{index + 1}.</StyledItemNumber>
						<MaterialPicker name={`${itemName}.materialId`} />
					</MaterialColumn>
				</Col>
				<Col sm={5}>
					<FormInput name={`${itemName}.name`} label={t('Name')} />
				</Col>
				<Col sm={4}>
					<FileUploader name={`${itemName}.filePath`} />
				</Col>
				<Col sm={3}>
					<FormInput
						name={`${itemName}.totalPrice`}
						label={t('Price')}
						suffix={CURRENCY_SUFFIX}
						readOnly
						type="number"
					/>
					<HiddenDeleteButton>
						<Popconfirm
							title={t('Are you sure want to remove this item?')}
							onConfirm={() => arrayHelpers.remove(index)}
							placement="topRight"
							okText={t('Remove')}
							okType="danger"
						>
							<Button
								icon={<DeleteOutlined />}
								shape="circle-outline"
								type="link"
							></Button>
						</Popconfirm>
					</HiddenDeleteButton>
				</Col>
			</StyledOrderRow>
		);
	}
};
