import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import { Colors } from '../../themeVariables';
import { useAppState } from '../../appContext/context';
import { UserRole } from '../../__generated__/types';

import { LinkItem } from './LinkItem';

const StyledMenu = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	> li {
		&:not(:first-of-type) {
			margin-top: 10px;
		}
	}
`;

const Container = styled.div`
	position: fixed;
	margin-top: 250px;
	width: 260px;
	overflow-y: scroll;
	top: 0;
	bottom: 0;
	::-webkit-scrollbar {
		width: 6px;
	}

	::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
	}

	::-webkit-scrollbar-thumb {
		background-color: ${Colors.DARK_GRAY4};
	}
`;

const CategoryName = styled.div`
	display: block;
	font-size: 12px;
	font-weight: 800;
	color: ${Colors.GRAY4};
	padding: 5px 15px;
`;

export const MainMenu: React.FC = () => {
	const hello = () => {
		console.log('Hola');
	};
	hello();
	const { t } = useTranslation();
	const { currentUser } = useAppState();
	return (
		<Container>
			<StyledMenu>
				<li>
					<CategoryName>{t('Orders')}</CategoryName>
					<LinkItem name={t('Add order')} to={'/orders/new'} />
					<LinkItem name={t('List orders')} to={'/orders/list'} />
				</li>
				<li>
					<CategoryName>{t('Customers')}</CategoryName>
					<LinkItem name={t('Add customer')} to={'/customers/new'} />
					<LinkItem name={t('Customers')} to={'/customers/list'} />
				</li>
				{currentUser?.role === UserRole.EXECUTIVE && (
					<li>
						<CategoryName>{t('Administration')}</CategoryName>
						<LinkItem name={t('Materials')} to={'/materials'} />
						<LinkItem name={t('Users')} to={'/users'} />
					</li>
				)}
			</StyledMenu>
		</Container>
	);
};
